import { isClient } from "common/helper"
import { findKey } from "lodash"

const urlConfig = {
  uat: {
    HOST: "https://uat.app.bam.ldrive.io",
    API_ENDPOINT: "https://uat.app.bam.ldrive.io/graphql",
  },
  staging: {
    HOST: "https://dev2.app.bam.ldrive.io",
    API_ENDPOINT: "https://dev2.app.bam.ldrive.io/graphql",
  },
  local: {
    HOST: "http://localhost:21803",
    API_ENDPOINT: "http://localhost:22201/graphql",
  },
}

const getEnvByHost = () => (isClient ? findKey(urlConfig, { HOST: window.location.origin }) : null)

export const nodeEnv = getEnvByHost() || "local"

const MOCK_MSAL_CONFIG = {
  uat: {
    REACT_APP_MSAL_CLIENT_ID: "eb1f0395-c240-40b3-b83f-c2b99730e266",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/6eaf065a-e93f-4f82-9f31-8976ef8d41ae",
    REACT_APP_MSAL_REDIRECT_URI: "https://uat.app.bam.ldrive.io",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "https://uat.app.bam.ldrive.io",
  },
  staging: {
    REACT_APP_MSAL_CLIENT_ID: "eb1f0395-c240-40b3-b83f-c2b99730e266",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/6eaf065a-e93f-4f82-9f31-8976ef8d41ae",
    REACT_APP_MSAL_REDIRECT_URI: "https://dev2.app.bam.ldrive.io",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "https://dev2.app.bam.ldrive.io",
  },
  local: {
    REACT_APP_MSAL_CLIENT_ID: "eb1f0395-c240-40b3-b83f-c2b99730e266",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/6eaf065a-e93f-4f82-9f31-8976ef8d41ae",
    REACT_APP_MSAL_REDIRECT_URI: "http://localhost:21803",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "http://localhost:21803",
  },
}

const MOCK_CONFIG = MOCK_MSAL_CONFIG[nodeEnv as keyof typeof MOCK_MSAL_CONFIG]

console.log("process", process)

const ICON_CONFIG = {
  bam: {
    attachment: "attach_email", //แนบเอกสาร, เอกสาร
    add_file_button: "NoteAddOutlined", //ปุ่มเพิ่มไฟล์
    detail_label: "BorderColor", //กรอกรายละเอียดการเบิก
    succes_detail_label: "Description", //รายละเอียดการยื่นคําขอ
    waiting_status: "PauseCircleFilled",
    cancel_status: "stop_circle",
    back_home: "",
  },
  scg: {
    attachment: "AttachFile", //แนบเอกสาร, เอกสารแนบ
    add_file_button: "Add",
    detail_label: "",
    succes_detail_label: "DescriptionOutlined", //รายละเอียดสวัสดิการที่ยื่นคําขอ
    waiting_status: "WatchLater",
    cancel_status: "RemoveCircle",
    back_home: "HouseOutlined", // กลับหน้าแรก
  },
}

const env = {
  // ...envalid,
  REACT_APP_MSAL_CLIENT_ID: MOCK_CONFIG.REACT_APP_MSAL_CLIENT_ID,
  REACT_APP_MSAL_AUTHORITY: MOCK_CONFIG.REACT_APP_MSAL_AUTHORITY,
  REACT_APP_MSAL_REDIRECT_URI: MOCK_CONFIG.REACT_APP_MSAL_REDIRECT_URI,
  REACT_APP_MSAL_LOGOUT_REDIRECT_URI: MOCK_CONFIG.REACT_APP_MSAL_LOGOUT_REDIRECT_URI,
  REACT_APP_LOGIN_SECRET: "zaisae4ael1Ahwutheiy5iequah7zu1s",
  HOST: urlConfig[nodeEnv as keyof typeof urlConfig].HOST,
  API_ENDPOINT: urlConfig[nodeEnv as keyof typeof urlConfig].API_ENDPOINT,
  TENANT_ID: "bpu",
  USER_CAN_SEE_REMAINING_BUDGET: false,
  THEME: "bpu", // bam | scg
}

console.log("host", urlConfig[nodeEnv as keyof typeof urlConfig])
console.log("env.ts", env)
export const themeIcon = ICON_CONFIG[env.TENANT_ID as keyof typeof ICON_CONFIG]

export default env
