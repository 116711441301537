import { ApolloClient, InMemoryCache, ApolloProvider, from, gql } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"

import { createUploadLink } from "apollo-upload-client"

import { env } from "configs"
import { Modal } from "components"
import appStore from "stores/appStore"
import PropTypes from "prop-types"
import { notifyError } from "utils/helper"

const TOKEN = "AUTHENTICATION_TOKEN"
const THEME_NAME = "themeName"
export const getToken = () => localStorage.getItem(TOKEN)
export const setToken = (token) => localStorage.setItem(TOKEN, token)
export const removeToken = () => localStorage.removeItem(TOKEN)
export const getThemeName = () => localStorage.getItem(THEME_NAME)
export const removeThemeName = () => localStorage.removeItem(THEME_NAME)

const httpLink = createUploadLink({
  uri: env.API_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  return {
    headers: {
      ...headers,
      "x-tenant-id": env.TENANT_ID,
      "Auth-Token": token || "",
    },
  }
})

const errorLink = onError((error) => {
  const { networkError, graphQLErrors } = error
  if (graphQLErrors[0]?.extensions.extensions) {
    switch (graphQLErrors[0]?.extensions.extensions.http.status) {
      case 401:
        notifyError(graphQLErrors[0]?.message)
        return
      case 403:
        Modal.alert({
          className: "ErrorModal",
          title: "Error",
          children: "เกิดข้อผิดพลาด",
          okButtonLabel: "ตกลง",
          onOk: async ({ close }) => {
            appStore.logout()
            close()
          },
          disableBackdropClick: true,
        })
        return
      case 500:
        notifyError(graphQLErrors[0]?.message)
        return
      default:
        return
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
})

export const ApiProvider = (props) => <ApolloProvider client={client}>{props.children}</ApolloProvider>

ApiProvider.propTypes = { children: PropTypes.node.isRequired }

export const resetStore = client.resetStore
export const clearStore = client.clearStore

export { gql }

export default client
