import { Box, Modal, Typography } from "components"
import { ClaimRequestHistoryItem } from "components/advance/ClaimRequestHistoryItem"
import { StatusTab } from "components/advance/StatusTab"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"

import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts"
import { every, isEmpty } from "lodash"
import styled from "styled-components"
import { checkShowPeriodSection, gql, paths } from "utils/helper"
import FilterClaimHistory from "./filterModal"
import InfiniteScroll from "react-infinite-scroll-component"
import Loader from "components/Loader"
import { ShowDetailOn } from "constants/enums/show-detail-on"
import PeriodSection from "components/PeriodSection"

const HistoryContainer = styled.div`
  margin-top: 24px;
`

const ContentContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`

const Contents = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const HistoryComponent = (props: any) => (
  <PageContent
    title="ประวัติการเบิก"
    type="secondary"
    titleCentered
    onBack={props.handleClickBack}
    showFilter
    hasFilter={props.hasFilter}
    openFilterModal={props.openFilterModal}
    filterCount={props.filterCount}
  >
    <Box padding="16px">
      {props.isShowPeriodSection && <PeriodSection mb="16px" textVariant="subtitle1" textColor="Text/Secondary" />}
      <StatusTab
        selectedStatus={props.selectedStatus}
        onClick={(status: EnumClaimRequestStatus) => props.handleChangeStatus(status)}
        isHistoryPage={true}
      />
      <HistoryContainer>
        {
          props.loading ? <Loader /> : <>
           {props.showNotFoundSearch ? (
          <Box>
            <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography variant="h3" color="Gray/Secondary Text">
                รายการค้นหา
              </Typography>
              <Typography variant="subtitle2" color="Gray/Primary Text">
                (0 รายการ)
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              position="fixed"
              top="40%"
              left="10%"
              right="10%"
            >
              <Typography variant="h3" color="Gray/Secondary Text">
                ไม่พบรายการที่ต้องการ
              </Typography>
              <Typography variant="h4" color="Text/Placeholder">
                กรุณาตรวจสอบข้อมูลตัวกรองที่ใช้ค้นหาอีกครั้ง
              </Typography>
            </Box>
          </Box>
        ) : (
          <InfiniteScroll
            dataLength={props.claimRequestHistory.length ?? 0}
            next={props.fetchMoreData}
            hasMore={props.hasMore}
            loader={<Loader />}
          >
            {props.claimRequestHistory.map((history: any) => (
              <ClaimRequestHistoryItem
                key={history.id}
                id={history.id}
                info={history.info}
                workflowSeq={history.workflowSeq}
                department={history.employee.department}
                createdAt={history.createdAt}
                referenceId={history.referenceId}
                amount={props.requestAmount(history)}
                onClick={(id: string) => props.handleClickHistoryItem(id)}
                owner={history.employee}
                createdBy={history.createdBy}
                userRole={props.currentUser.role}
                status={history.status}
              />
            ))}
          </InfiniteScroll>
            )}
             {props.claimRequestHistory.length === 0 && !props.hasMore && !props.hasFilter && (
          <ContentContainer>
            <Contents>
              <Typography variant="h3" color="Text/Primary Text">
                ไม่มีรายการขอเบิก
              </Typography>
              <Typography variant="body1" color="Text/Gray Preview" style={{ fontSize: "16px", marginTop: "17px" }}>
                ไม่พบข้อมูลการขอเบิกในสถานะนี้
              </Typography>
            </Contents>
          </ContentContainer>
        )}
          </>
        }
       
      </HistoryContainer>
    </Box>
  </PageContent>
)

const API = {
  GET_CLAIM_REQUEST_HISTORY: gql`
    query GET_CLAIM_REQUEST_HISTORY($employeeId: String!, $status: String, $filters: JSON, $pagination: JSON) {
      claimRequestHistory(
        input: { employeeId: $employeeId, status: $status, filters: $filters, pagination: $pagination }
      ) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        createdBy
        referenceId
      }
    }
  `,
  GET_EMPLOYEE_OPTONS: gql`
    query GET_EMPLOYEE_OPTONS {
      getEmployeeOptions {
        label
        value
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
    settingInfo: stores.settingStore.setting,
  })),
  withHooks((props: any, hooks: any) => {
    const { useQuery, useCallback, useEffect, useMemo, useState, useUrlParam } = hooks
    const { currentUserInfo, settingInfo } = props
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const queryParams = useUrlParam()
    const { tab } = queryParams

    const [variables, setVariables] = useState({
      employeeId: currentUser.id,
      status: EnumClaimRequestStatus.WAITING,
      pagination: { offset: 0, limit: 10 },
    })

    // const [status, setStatus] = useState(EnumClaimRequestStatus.WAITING)
    const { data, loading, refetch, fetchMore } = useQuery(API.GET_CLAIM_REQUEST_HISTORY, {
      variables: {
        ...variables,
        status: tab,
        filters: variables.filters,
      },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        if (data.claimRequestHistory.length === 0) {
          setHasMore(false)
        }
      },
    })

    const [canSubmit, setCanSubmit] = useState(false)
    const [hasFilter, setHasFilter] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [filterCount, setFilterCount] = useState(0)

    const { data: employee, loading: loadingEmployee, error: errorEmployee } = useQuery(API.GET_EMPLOYEE_OPTONS, {
      fetchPolicy: "network-only",
    })

    const employeeOptions = useMemo(() => {
      if (loadingEmployee || errorEmployee) {
        return []
      }
      return employee?.getEmployeeOptions || []
    }, [employee, loadingEmployee, errorEmployee])

    const isShowPeriodSection = useMemo(() => {
      if (!settingInfo) {
        return false
      }
      return checkShowPeriodSection(ShowDetailOn.ON_HISTORY_PAGE, settingInfo)
    }, [settingInfo])

    const refetchClaimHistory = useCallback(async () => {
      await refetch({ ...variables })
      const filterValues = variables.filters
      if (!isEmpty(filterValues)) {
        setHasFilter(true)
        setFilterCount(Object.values(filterValues).filter((item) => !!item).length)
      }
      if (every(filterValues, (value) => !value)) {
        setHasFilter(false)
        setFilterCount(0)
      }
    }, [refetch, variables])

    const openFilterModal = useCallback(() => {
      // @ts-ignore
      Modal.open({
        className: "FilterModal",
        children: <FilterClaimHistory employeeOptions={employeeOptions} initialValues={variables.filters} />,
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        onOk: async ({ close, values }: any) => {
          paths.historyPath({ tab: tab, filters: values }).push()
          setVariables({ ...variables, filters: values })
          setCanSubmit(true)
        },
      })
    }, [employeeOptions, variables, tab])

    useEffect(() => {
      if (canSubmit) {
        const fetchData = () => {
          refetchClaimHistory()
          setCanSubmit(false)
          // @ts-ignore
          Modal.close()
        }
        fetchData()
      }
    }, [canSubmit, refetchClaimHistory])

    const fetchMoreData = useCallback(() => {
      fetchMore({
        variables: {
          status: tab,
          filters: variables.filters,
          pagination: {
            offset: data?.claimRequestHistory.length,
            limit: 10,
          },
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (fetchMoreResult.claimRequestHistory) setHasMore(fetchMoreResult.claimRequestHistory.length > 0)
          if (!fetchMoreResult) return prev
          return {
            claimRequestHistory: [...prev.claimRequestHistory, ...fetchMoreResult.claimRequestHistory],
          }
        },
      })
    }, [fetchMore, tab, variables.filters, data?.claimRequestHistory.length])

    const requestAmount = useCallback((history: any) => {
      return history?.info?.values?.inputs[history.info.amountFieldName] || 0
    }, [])

    const handleClickHistoryItem = useCallback(
      (id: string) => {
        if (tab === EnumClaimRequestStatus.DRAFT) {
          paths.editDraftRequestPath(id, { tab: tab }).push()
        } else {
          paths.historyDetailPath(id, { tab: tab }).push()
        }
      },
      [tab],
    )

    const handleClickBack = useCallback(() => {
      paths.landingPath().push()
    }, [])

    const isRepresent = useCallback(
      (requesterId: string) => {
        return requesterId !== currentUser.id
      },
      [currentUser.id],
    )

    const handleChangeStatus = useCallback(
      (status: EnumClaimRequestStatus) => {
        // setStatus(status)
        paths.historyPath({ tab: status, filters: variables.filters }).push()
        setVariables({ ...variables, status: status })
        setHasMore(false)
      },
      [variables],
    )

    // useEffect(() => {
    //   if (tab) {
    //     refetch({ ...variables })
    //   }
    // }, [refetch, tab, variables])

    useEffect(() => {
      if (data?.claimRequestHistory.length === 0) {
        setHasMore(false)
      }
    }, [data])

    const showNotFoundSearch = useMemo(() => data?.claimRequestHistory.length === 0 && hasFilter, [data, hasFilter])

    return {
      claimRequestHistory: data?.claimRequestHistory || [],
      requestAmount,
      handleClickHistoryItem,
      handleClickBack,
      isRepresent,
      handleChangeStatus,
      selectedStatus: tab,
      currentUser,
      loading,
      openFilterModal,
      hasFilter,
      hasMore,
      fetchMoreData,
      filterCount,

      showNotFoundSearch,

      isShowPeriodSection,
    }
  }),
)

export default enhancer(HistoryComponent)
